@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

/**************************[ BASE ]**************************/
body {
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	color: #000;
	background-color: #fff;
}

a, body, i, p, span, u {
	font-size: 18px;
	line-height: 24px;
}
h1 {
	font-size:32px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Open Sans', sans-serif;
	font-weight:bold;
	color:#000;
}
h2 {
	font-size:28px;
	font-weight:bold;
	letter-spacing:0;
	text-transform: none !important;
	line-height:20px;
	font-family: 'Open Sans', sans-serif;
}
h3 {
	font-size:20px;
	font-weight:bold;
	line-height:inherit;
	font-family: 'Open Sans', sans-serif;
}
h4 {
	font-weight:bold;
	font-size:16px;
	font-family: 'Open Sans', sans-serif;
}
h6 {
	font-size:20px;
	letter-spacing:3px;
	text-transform:uppercase;
	font-family: 'Open Sans', sans-serif;
}
.wrapper {
	border-top:none;
}

#opc-login h3, #subscribe-form label, .accordion .heading, .block .block-title, .box-account .box-head h2, .caption .heading, .feature .heading, .footer .heading, .gen-tabs .tabs a, .main-font, .mobnav-trigger, .nav-mobile li.level0>a, .nav-mobile li.level1>a, .nav-mobile li.level2>a, .nav-regular .nav-submenu--mega>li>a, .nav-regular li.level0>a, .order-items .order-comments h2, .order-items h2.sub-title, .order-items h2.table-caption, .product-view .box-reviews dt .heading, .products-grid .product-name, .products-list .product-name, .section-title, body, h1, h2, h3, h3.product-name, h4, h5
{
	font-family: 'Open Sans', sans-serif !important;
}

.gen-tabs .tabs a.current {
	color:#000;
}

/**************************[ HEADER ]**************************/
.header a, .header p, .header span {
	font-size: 16px;
	color: #fff;
	font-family: 'Open Sans', sans-serif;
}
.header .dropdown, .header-top {
	line-height: normal;
}

/* Top bar */
.header-container {
	background-color: #fff;
	padding-bottom: 0;
}
.header-top-container {
	background-color:#000;
	box-shadow:0 0 0 1px #e5e5e5;
}

.header-top-container .header-top {
	line-height: normal;
}
.welcome-msg *, .userCode, .userName {
	color: #fff !important;
}

/* Logo */
.logo img {
	max-width: 250px;
}

/* Search bar */
.search-wrapper-centered .form-search {
	position: relative;
}
.search-wrapper-centered .form-search .input-text {
	width: 100%;
	background-color: #fff;
	border-color: #999;
	color: #000;
	font-family: 'Open Sans', sans-serif;
	display: block;
}
.search-wrapper-centered .form-search .button-search {
	position: absolute;
	top: 0;
	right: 0;
}
.search-wrapper-centered .form-search .button-search .fa {
	font-size: 16px;
	color: #000;
	padding: 10px;
}
.search-wrapper-centered .form-search-globalfilter {
	position: relative;
}
.search-wrapper-centered .form-search-globalfilter label[for="ignore-filter-ignore"] {
	font-size: 12px;
}

/* Cart Widget */
.user-menu, .user-menu a {
	color: #fff;
}
.dropdown-toggle *, .header span.cart-total, .header span {
	color: #000;
}
.dropdown-toggle .value {
	color: #fff;
}
.dropdown.open .dropdown-toggle .value {
	color: #000;
}
.header .open > .dropdown-toggle.cover > div {
	background-color: transparent;
}
.feature-icon-hover .caret {
	padding-top: 10px;
	font-weight: 700;
	border-top-color: #fff;
	border-width: 6px 5px 0;
}
.dropdown-toggle .icon.close-to-text {
	display: none;
}
.dropdown-toggle .hide-below-1280,
.dropdown-toggle .amount {
	padding: 0.5em 5px;
	font-size: 14px;
}
.empty span.price {
	margin-top: 5px;
	padding-right: 0.5em;
}
.header .fa.fa-shopping-cart {
	position: relative;
	top: 8px;
	left: -15px;
	font-size: 18px;
}
#root-wrapper span.fa {
	font-size: 18px;
}

/* Menu */
.custom-freeshipping-note a, .links > li > a, .nav-regular li.level0 > a > span {
	color: #777;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif;
}
.links > li > a:hover {
	background-color: #B3007E;;
	color: #ffffff !important;
}
#nav {
	margin-top: 0;
	margin-bottom: 0;
}
#nav, .mobnav-trigger {
    background-color: #fff;
	border-bottom: none;
}
.nav-regular .nav-item.level0:hover > a,
.nav-regular .nav-item.level0:hover > a > span,
.nav-mobile .nav-item.level0 > a:hover {
	background-color: transparent;
	color: #000;
}
.nav-regular li.level0 > .nav-panel--dropdown, .nav-regular .mega > .nav-panel--dropdown, .nav-regular .classic > .nav-panel--dropdown {
	border-top: 5px solid #000;
}
#nav .nav-panel--dropdown a {
	color: #000;
	font-size: 16px;
}
#nav .nav-panel--dropdown a > span {
	font-size: 16px;
}
#nav .nav-panel--dropdown a:hover {
	color: #000;
}
.mobnav-trigger.active {
	background-color: #000;
}
.cms-index-index .nav-regular .nav-item--home > a, .nav-regular .nav-item.level0.active > a, .nav-mobile .nav-item.level0.current > a {
	background-color: transparent;
	color: #000;
}
#nav .nav-item--home .fa-home {
	color: #000 !important;
	padding: 0.3rem;
}
#root-wrapper a:hover span.fa:before {
	color: #000;
}
#nav .nav-item.level0.parent:hover > a .caret {
	border-top-color: #000;
}
#nav .nav-panel--dropdown a:hover .caret {
	border-left-color: #000;
	border-right-color: #000;
}
#mini-cart .actions button {
	width: 100%;
}
#mini-cart .actions button.button span {
	background-color: transparent !important;
	width: 100%;
}
#mini-cart .actions button.button span span {
	background-color: #B3007E !important;
	padding: 0;
	border-radius: 10px;
	max-height: 45px;
	font-size: 18px;
	margin-top: 10px; color:#fff;
}
#mini-cart .actions button.button span span:hover {
	background-color: #F9780E !important;
}
.dropdown-menu > li * {
	float: none;
	display: inline-block;
}
.dropdown-menu > li > a {
	display: inline-block;
}

/**************************[ BODY ]**************************/
.breadcrumbs a, .breadcrumbs li {
	font-size: 14px;
}
.breadcrumbs li span {
	margin: 10px 4px 0;
	font-size: 14px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.breadcrumbs li span.breadcrumb-separator {
	background: url(../images/img/pix.png) 0 -129px no-repeat;
	width: 7px;
	height: 5px;
	display: block;
	float: left;
	text-indent: -9999px;
	overflow: hidden;
	margin: 12px 4px 0;
}
h1, h1.banner-h1, .banner-h1 a {
	font-size: 32px;
	color: #000;
	letter-spacing: 3px;
	text-transform: uppercase;
}
.pager .amount, .sorter .amount {
	line-height: 36px;
}
.toolbar label, .toolbar p, .toolbar span {
	font-size: 14px;
}
.products-grid.single-line-name .product-name {
	overflow: visible;
	text-overflow: initial;
	word-wrap: break-word;
	white-space: normal;
}
.products-grid.centered .item {
	font-size: 14px;
	line-height: 20px;
}
.products-grid .product-name, h3.product-name a {
	font-size: 16px;
}
.bootstrap-styles h2 {
	letter-spacing: 0;
}
.product-view .btn-cart span, button.button span {
	color: #fff;
}
.add-cart-wrapper button span span {
	width: 140px;
	max-width: 140px;
}
.product-view .btn-cart span, button.button span {
	margin: 0;
	background-color: #B3007E;
	border-radius: 0;
	padding: 0;
}
button, .button, .button a, button a {
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
}
button.button span {
	font-size: 18px; 
    border-radius: 10px !important;
}
button.button span span {
	padding: 0 24px;
    
}
.add-cart-wrapper button:last-child {
	margin-top: 10px;
}
.btn-filled:hover, .button:hover span {
	background-color: #F9780E !important;
}
.products-grid.centered .item {
	line-height: 20px;
}
.item .price-box .price, .item .price-box .price span, .xm-owl-carousel .price-box .price, .xm-owl-carousel .price-box .price span {
	font-size: 18px;
	color: #000; 
}
.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
	color: #000;
}
.main .col-main, .main .sidebar {
	margin-top: 1%;
	margin-bottom: 1%;
}
.account-login {
	margin: 15px 0 0 0;
	background-color: #fff;
}
.block-title span {
	font-size: 20px;
}
.form-list label {
	font-weight: unset;
}
div[class^='account'] p, div[class^='account'] h2 {
	text-align: left;
}

div[class^='account'] .form-list input.input-text {
	float: left;
}
.category-title, .my-account .page-title {
	border-bottom: none;
}
.pager .pages li a {
	background-color: #f5f5f5;
}
.pager .pages li.current,
.pager .pages li a {
	width: 30px;
	height: 30px;
	line-height: 30px;
}

/**************************[ Footer ]**************************/
.footer-container {
	background-color: #394A59;
}
.footer-container,  .footer-container2, .footer-primary.footer.container, .footer-top
{
	background-color: #394A59;
}
.footer-top-container {
	padding-top: 10px; 
    background-color: #394A59;
}
.footer-primary-container {
	background-color: transparent;
	padding-top: 10px;
	padding-bottom: 10px;
}
.footer-bottom-container.section-container {
	background-color: #333;
	color: #999; 
    display:none;
}


/************************** MICS **************************/
/*footer*/
#footer h3, #footer span {
     color:#fff;
}
#scroll-to-top {
     background-color:#BEBEBE ;
}
#scroll-to-top:hover {
     background-color:#B3007E ;
}
#footer .inner-wrapper {
     display:flex;
}
@media only screen and (min-width:768px) {
     #footer .outer-wrapper{
         display:flex;
    }
     #footer .outer-wrapper > div:nth-child(1) {
         width:50%;
    }
     #footer .outer-wrapper > div:nth-child(2) {
         margin:0 auto;
    }
     #footer .inner-wrapper > div {
         width:50%;
    }
}
@media only screen and (max-width:767px) {
     #footer .inner-wrapper > div {
         margin:0 auto 0 0;
    }
     #footer .outer-wrapper > div:nth-child(2) {
         margin-top:20px;
    }
}


/*Header*/
.header-top-container {
     background-color:#B3007E;
     box-shadow:none;
}
.header-top-container .module-header-multi{
     margin-left:0;
     margin-right:0;
     width:100%;
}
/*Align the text on the top header*/
.top-header-container .module-header-multi span, .top-header-container .module-header-multi a{
     line-height:2em;
}
.top-header-container .module-header-multi p, .header-txt {
     margin-bottom:0 !important;
     line-height:2em;
}
.top-header-container .item-welcome-msg {
     margin-left:0 !important;
}
.top-header-container .item.item-user-links {
     margin-right:0;
}
.top-header-container .item.item-user-links ul.links > li:last-child a{
     padding-right:0;
}
.top-header-container .links>li>a, .welcome-txt p, .welcome-txt span, .welcome-txt a{
     font-size:16px;
     font-weight:700;
     color:#fff !important;
}
.desktop-top-header {
     padding-top:10px;
}
.desktop-top-header .header-top-container, .mobile-header .header-top-container {
     background-color:#fff;
}
/*Set all fa icons to be normal style*/
.std i {
     font-style:normal;
}
.desktop-top-header .grid-container {
     display:flex;
}
.desktop-top-header .logo-column, .desktop-top-header .cart-column {
     margin:auto 0;
}
.desktop-top-header .search-column {
     margin:auto;
}
/*Cart*/
#mini-cart .empty {
     display:none;
}
.fa.fa-shopping-cart {
     float:right;
     left:inherit !important;
     margin-left:10px;
     top:2px !important;
     font-size:22px !important;
}
/*Change the text 'Switch Customer' to a FA icon for small width devices.*/
@media only screen and (max-width:1199px) {
     .userSwitch a {
         text-indent:-9999px;
         position:absolute;
    }
     .userSwitch a::after {
         content:"\f2c3";
    }
     .userSwitch a::after {
         font-family:fontAwesome;
         font-size:14px;
         text-indent:0;
         float:left;
    }
}
/*Search*/
.desktop-top-header .form-search {
     width:90%;
     margin:0 auto;
}
.desktop-top-header .module-search .form-search input::placeholder {
     color:#000 !important;
     font-weight:400;
}
.module-search .form-search .button-search .fa {
     color:#000 !important;
     font-weight:600;
}
.desktop-top-header .module-search .form-search .input-text {
     border:2px solid #B3007E ;
     border-radius:20px;
     background-color:#fff;
     font-weight:400;
     font-size:16px;
     text-align:center;
}
/*Menu*/
.desktop-category-column {
     margin-top:20px;
}
.desktop-category-column .module-category-menu .nav-item.nav-item--home {
     display:none;
}
.desktop-category-column .nav-regular li.level0>a>span {
     color:#000;
     font-size:18px;
}
.desktop-category-column .nav-regular .nav-panel li.classic {
     margin-bottom:5px;
}
.desktop-category-column .nav-regular li.level0>a {
     text-transform:inherit;
}
.desktop-category-column #nav {
     margin-left:0;
}
#header {
         border-bottom:2px solid #B3007E;
         padding-bottom:10px;
    }
/*Hide-below-960/hide-above-960 doesn't work in all cases, so add the code but not for the mini cart*/
@media only screen and (max-width:959px) {
     .hide-below-960 {
         display:none !important;
    }
     #mini-cart .hide-below-960 {
         display:inline-block !important;
    }
}
@media only screen and (min-width:960px) {
     .hide-above-960 {
         display:none !important;
    }
}
@media only screen and (min-width:768px) {
     .hide-above-768 {
         display:none !important;
    }
}
@media only screen and (max-width:767px) {
     .hide-below-768 {
         display:none !important;
    }
}
/*Change the account menu icon for mobile/tablet*/
.fa-vcard:before{
     content:"" !important;
}
.fa-vcard:after{
     content:"\f007";
     color:#fff;
}
/*Inline search results*/
 #inline-search-results {
     right:0;
     left:initial;
}
@media only screen and (min-width:960px) {
     #inline-search-results {
         width:160%;
         right:0;
         left:initial;
    }
     #inline-search-results .grid12-8.results-right {
         width:68.5%;
    }
     #inline-search-results li.grow {
         width:45%;
    }
}
@media only screen and (max-width:768px) {
     #inline-search-results {
         width:96%;
         left:0 !important;
         margin:auto;
         max-height:700px;
         overflow:auto;
    }
     #inline-search-results li.grow {
         width:45% !important;
    }
}
/*Tablet Header*/
@media only screen and (max-width:959px) {
     .logo img {
         max-width:200px;
    }
     .desktop-top-header .search-column {
         width:45%;
    }
     .desktop-top-header, .desktop-top-header .cart-column, .desktop-top-header .cart-column .module-header-multi {
         overflow:unset;
    }
    .cart-column .fa.fa-shopping-cart {
            top: 10px !important;
    }
     
}
/*Mobile Header*/
@media only screen and (max-width:767px) {
     .top-header-container, .mobile-header {
         margin-bottom:5px !important;
    }
     .mobile-header .grid-full.module-header-multi {
         display:flex;
         overflow:hidden !important;
    }
     .mobile-header .grid-full.module-header-multi > div{
         margin:auto;
    }
     .mobile-header .fa.fa-bars, .mobile-header .fa.fa-vcard:after {
         color:#000 !important;
    }
     .mobile-header .header .logo-wrapper {
         text-align:left !important;
         margin:0 !important;
    }
    .mobile-header .logo img {
        max-width:180px;
    }
     .mobile-header .cart-total {
         display:none;
    }
     .mobile-header .item-search {
         margin-left:0 !important;
    }
     .mobile-header .item-search .fa-search{
         padding-right:0 !important;
    }
     /*Search on mobile*/
     .mobile-header div.form-search {
         width:94%;
         margin:0 auto;
    }
     .mobile-header .module-search .form-search .input-text {
         font-size:16px !important;
    }
     /*Search*/
     .mobile-header .module-search .form-search .input-text {
         display:none;
    }
     .mobile-header .module-search .button.button-search {
         position:unset!important;
    }
     .mobile-header .item-search.fly-out {
         position:fixed;
         display:block!important;
         top:0;
         right:0;
         left:0;
         bottom:0;
         z-index:9999;
         width:100%!important;
         background-color:#fff;
         margin-left:0;
    }
     .mobile-header .item-search.fly-out .form-search .button {
         right:10px;
         position:absolute!important;
         margin:0;
         padding:0;
         font-size:inherit;
    }
     .mobile-header .item-search.fly-out .form-search .button-search {
         right:45px;
    }
     .mobile-header .form-search .button-close .fa {
         font-size:16px;
         color:#000;
         padding:10px;
         font-style:normal;
    }
}
/*Header ends*/
/*category pages*/
.product-search-container {
     width:100%;
     margin-left:0;
     margin-right:0;
}
.product-search-container .page-title h1 {
     text-transform:inherit;
}
.sidebar-wrapper.module-category-filters .block-title span {
     text-transform:Capitalize;
}
.sidebar-wrapper.module-category-filters .block-title, .accordion-style1 li a {
     border:none;
}
.product-listing {
     margin-top:0 !important;
}
.pager {
     border:none;
}
.pager .pages li a{
     background-color:#BEBEBE ;
     color:#000;
}
.pager .pages li.current {
     background-color:#F9780E ;
     color:#fff;
}
.pager .pages li a:hover {
     background-color:#F9780E;
}
@media only screen and (max-width:959px) {
     .product-listing {
         width:98% !important;
    }
     .fa.fa-sliders {
         font-size:22px !important;
    }
}
@media only screen and (max-width:767px) {
     .toolbar-section p, .toolbar-section a, .toolbar-section strong, .toolbar-section label, .toolbar-section li, .toolbar-section .per-page{
         font-size:12px;
    }
     .toolbar-section select {
         height:26px;
         padding:3px;
    }
     .pager .pages li {
         height:25px;
         line-height:25px;
    }
}
@media only screen and (max-width:767px) {
     .products-grid .item button.button span span {
         padding:0 12px !important;
         height:auto;
         line-height:2em;
         width:120px !important;
    }
}
.category-products-grid .item:hover {
     z-index:10;
     box-shadow:0 0 20px rgba(0,0,0,.2);
     border-color:transparent;
     transition:box-shadow .4s ease-out;
     -moz-transition:box-shadow .4s ease-out;
     -webkit-transition:box-shadow .4s ease-out;
     -o-transition:box-shadow .4s ease-out;
}
.category-products-grid .item {
     border:none;
     margin-bottom:70px;
}
.category-products-grid .item .qty-box {
     height:40px;
     margin-bottom:10px;
}
/*List elements*/
.page-content ul, #product-tabs .std ul {
     list-style:disc;
     margin-left:25px;
}
